'use client';
import * as Yup from 'yup';

import {
  AFFILIATE_REFERRAL_COOKIE,
  AFFILIATE_VISIT_COOKIE,
} from '@components/page-tracking/tracking-client';
import routes from '@config/routes';
import { useAuthUserType } from '@hooks/use-auth-user-type';
import instance from '@instance';
import { useCompleteAccountStore } from '@store/complete-account-store';
import { AUTH_PROVIDERS } from '@utils/auth/providers';
import getCookie from '@utils/get-cookie';
import { loginValidationSchema } from '@utils/validation/auth-validation.schema';
import {
  ValidatePasswordlessCode,
  sendVerificationCode,
  signup,
  signupValidationSchema,
  validateCode,
  validateCodeSchema,
  type SignupResponse,
} from '@v2/action';
import { checkUserExisting } from '@v2/action/test';
import { AlertToast, AlertTriangle, CheckCircle } from '@v2/ui';
import { deleteCookie } from 'cookies-next';
import { useFormik } from 'formik';
import { getSession, signIn } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useCountdown } from 'usehooks-ts';

export const loginSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email to continue')
    .required('Email is required'),
});

type useSigninProps = {
  onSubmitCallback: (data: string) => void;
};
export function useSignin({ onSubmitCallback }: useSigninProps) {
  const [loading, setLoading] = useState(false);

  const userType = useAuthUserType();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const data = await checkUserExisting({ ...values, userType });

      setLoading(false);
      onSubmitCallback(data);
    },
  });

  const models = {
    formik,
    loading,
  };
  const operations = {};
  return [models, operations] as [typeof models, typeof operations];
}

interface SignUpProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatPassword: string;
}

type useSignupCallback = {
  onSuccessCallback: (res: SignupResponse) => void;
  onErrorCallack: (err: string) => void;
};
export function useSignup({
  onSuccessCallback,
  onErrorCallack,
}: useSignupCallback) {
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const searchParams = useSearchParams();
  const email = searchParams!.get('email')!;

  const { firstName, lastName, password, repeatPassword, clearState } =
    useCompleteAccountStore();

  const formik = useFormik<SignUpProps>({
    initialValues: {
      email,
      firstName,
      lastName,
      password,
      repeatPassword,
    },
    validationSchema: signupValidationSchema,
    onSubmit: async (val) => {
      try {
        setLoading(true);

        const affiliationId = getCookie(AFFILIATE_REFERRAL_COOKIE);
        const res = await signup({ ...val, affiliationId });

        console.log({ res });

        const newsLetter = await instance.server(routes.addTagToUser, {
          body: JSON.stringify({
            email: email.toLowerCase(),
            tag: 'Platform User',
            firstName,
            lastName,
          }),
        });
        onSuccessCallback(res);

        if (typeof res === 'string') {
          onErrorCallack(res);
          return setLoading(false);
        }
        if (res) {
          clearState();
          setVerifyEmail(true);
          deleteCookie(AFFILIATE_REFERRAL_COOKIE);
        }
      } catch (e: any) {
        console.log(e);
      }
      setLoading(false);
    },
  });

  const models = {
    loading,
    isEmailVerified: verifyEmail,
    formik,
  };

  const operations = {};

  return [models, operations] as [typeof models, typeof operations];
}

type UseConfirmIdentityProps = {
  onSuccessCallack: (res: ValidatePasswordlessCode) => void;
};

type ValidateCodeArgs = Yup.InferType<typeof validateCodeSchema>;

export function useConfirmIdentity({
  onSuccessCallack,
}: UseConfirmIdentityProps) {
  const [loading, setLoading] = useState(false);

  const userType = useAuthUserType();
  const handleVerifyCode = async (val: ValidateCodeArgs) => {
    setLoading(true);

    const res = await validateCode(val);

    if (typeof res === 'string' && res === 'Invalid code.') {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Invalid code.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
      setLoading(false);

      return;
    }

    if (res) {
      onSuccessCallack(res);
    }
    setLoading(false);
  };

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 30,
      intervalMs: 1000,
    });

  const handleResendCode = async (email) => {
    const res = await sendVerificationCode({ email, userType });

    if (res === 'No agency found.') {
      return toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'No active agency found'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }
    toast.custom(
      (t) => (
        <AlertToast
          {...t}
          title="Success"
          content={'code has  been sent to your email.'}
          icon={<CheckCircle />}
        />
      ),
      { position: 'top-right' }
    );

    resetCountdown();
    startCountdown();
  };

  const models = {
    countDuration: count,
    loading,
  };

  const operations = { handleResendCode, startCountdown, handleVerifyCode };
  return [models, operations] as [typeof models, typeof operations];
}

type UseManualLoginProps = {
  email: string;
  onSuccessCallback: () => void;
};
export function useManualLogin(props: UseManualLoginProps) {
  const { email, onSuccessCallback } = props;
  const [loading, setLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);
  const userType = useAuthUserType();

  const formik = useFormik({
    initialValues: {
      email,
      password: '',
    },
    validateOnChange: true,
    validationSchema: loginValidationSchema,
    onSubmit: async (data) => {
      try {
        setLoading(true);

        const response = await signIn(AUTH_PROVIDERS.credentials, {
          ...data,
          userType,
          rememberMe,
          redirect: false,
        });

        if (!response) {
          return toast.custom(
            (t) => (
              <AlertToast
                {...t}
                title="Oops, something went wrong"
                content={'Please try again in a minute.'}
                icon={<AlertTriangle />}
              />
            ),
            { position: 'top-right' }
          );
        }

        if (response.error === 'CredentialsSignin') {
          return toast.custom(
            (t) => (
              <AlertToast
                {...t}
                title="Oops, something went wrong"
                content={'Invalid credentials.'}
                icon={<AlertTriangle />}
              />
            ),
            { position: 'top-right' }
          );
        }

        const userIp = getCookie('user-ip')?.toString() || 'IP not found';
        const anonymousId =
          getCookie('anonymous-user-id')?.toString() ||
          'anonymous-user-id not found';

        const userSession = await getSession();

        if (userSession?.user) {
          instance.server(routes.updateAnonymousUser(anonymousId), {
            body: JSON.stringify({
              ipAddress: userIp,
              userId: userSession.user.id,
            }),
            method: 'PATCH',
          });
        }

        deleteCookie(AFFILIATE_VISIT_COOKIE);
        onSuccessCallback();
        // router.push(v2Links.home);
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
  });

  const models = {
    formik,
    rememberMe,
    loading,
  };
  const operations = {};

  return [models, operations] as [typeof models, typeof operations];
}
